<template>
  <div class="tai-detail my-second bgcolor">
    <Head :title="$t('common.jiaoyi_list')" :show="true" />
    <div class="tai-detail-main">
        <div class="tai-top">
           <div class="tai-top-left" @click="showtime=true">
              <p>{{year}}{{$t('common.year')}}{{month}}{{$t('common.month')}} </p>
              <van-icon name="arrow-down" class="icon"></van-icon>
           </div>
           <div class="tai-top-right" @click="show=true">
             <p>{{withType}}</p>
             <van-icon name="arrow-down"  class="icon"></van-icon>
           </div>
        </div>
        <div class="tai-detail-list" v-if="list.length>0">
            <ul>
            <MyList ref="mylist" :listadd="listadd">
              <li v-for="(l,i) in list" :key="i">
                  <div class="li-top li-item">
                      <p class="li-top-left">{{l.type}}</p>
                      <p class="li-top-right " :class="l.is_send==2?'p-active':''">
                         <span>{{l.is_send==2?'+':'-'}}</span>
                        ฿{{l.amount}}</p>
                  </div>
                  <div class="li-bottom li-item">
                      <p>{{l.time}}</p>
                      <p :class="l.is_pending==1?'p-active':''">{{l.remark}}</p>
                  </div>
              </li>
            </MyList>
            </ul>
        </div>
         <div v-else class="no-box">
                <img class="juan-no" :src="require('@/assets/images/xuqiu-no.png')" alt="">
                <p class="no-text">{{$t('common.no_th_list')}}</p>
          </div>
    </div>

      <!-- 币种选择  -->
     <van-popup class="login-popup taione-one" v-model="show" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('wallet.choose')}}{{$t('wallet.Transaction_Type')}}</p>
            </div>
            <div class="btn-list">
                <van-button @click="changeType(-1,$t('common.all')+$t('wallet.Transaction_Type'),'-1','-1')"  class="btn-list-item " :class="current==-1?'active':''">{{$t('score.all')}}</van-button>
                <van-button @click="changeType(0,$t('common.withwral'),'80','80')"  class="btn-list-item"  :class="current==0?'active':''">{{$t('common.withwral')}}</van-button>
                <van-button @click="changeType(1,$t('common.buy'),'84','84')"   class="btn-list-item"  :class="current==1?'active':''">{{$t('wallet.exchange')}}</van-button>
                <van-button @click="changeType(2,$t('home.bet'),'60','63')"   class="btn-list-item"  :class="current==2?'active':''">{{$t('score.auction')}}</van-button>
                <van-button @click="changeType(3,$t('common.payout'),'31','31')"  class="btn-list-item"  :class="current==3?'active':''">{{$t('wallet.withdraw')}}</van-button>
            </div>
            <van-button class="cancel-btn sure-btn" @click="show=false">{{$t('login.cancel')}}</van-button>
        </div>
    </van-popup>

     <!-- 选择年月 -->
    <van-popup v-model="showtime" class="time-popup" position="bottom"   duration="0.2" >
        <van-datetime-picker
            v-model="currentDate"
            type="year-month"
            class="time-picker"
            @cancel="showtime=false"
            @confirm="onConfirm"
            :formatter="formatter"
        />
    </van-popup>
  </div>
</template>
<script>
export default {
  name:'wallet-thaiDetail',
  data(){
    return {
        show:false,
        showtime:false,
        currentDate:new Date(),
        page:1,
        pageSize:50,
        total:0,
        max:'-1',
        min:'-1',
        list:[],
        year:"",
        month:"",
        oldlist:[],
        current:-1,
        withType:this.$t('common.all')+this.$t('wallet.Transaction_Type')
    }
  },
  mounted(){
        //  泰铢交易明细
      this.$ajax.walletlog({
          current_id:8011,
          page:this.page,
          pageSize:this.pageSize
      })
      .then(res=>{
          if(res.code==200){
               this.list = res.data.data;
               this.oldlist = res.data.data;
               this.total = res.data.total
          }
      }) 

      this.year = new Date().getFullYear();
      this.month = new Date().getMonth() + 1;
  },
  methods:{
      listadd(cb1,cb2){
        if(this.current=='-1'){
            if(this.page<Math.ceil(this.total/this.pageSize)){
                this.page++;
                this.$ajax.walletlog({
                    current_id:8011,
                    page:this.page,
                    pageSize:this.pageSize
                })
                .then(res=>{
                    if(res.code==200){
                        // this.list = this.list.concat(res.data.list);
                        this.oldlist = this.oldlist.concat(res.data.data);
                        this.total = res.data.total;
                        this.changeList(this.max,this.min)
                    }
                    cb1();
                })
            }else{
                cb2()
            }
        }
      
      },
      changeList(max,min){
           if(max=='-1'&&min=='-1'){
               this.list = [...this.oldlist]
           }else if(max=="0"&&min=="0"){
               this.list = [];
           }else{
               this.list = this.oldlist.filter(item=>{
                   return item.rechargeType >= min * 1 && item.rechargeType <= max * 1;
               })
           }
      },
      changeType(current,type,min,max){
           this.current = current;
           this.withType = type;
           this.max = max
           this.min = min 
            //   过滤代码
           this.show = false;
           if(max=='-1'&&min=='-1'){
               this.list = [...this.oldlist]
           }else if(max=="0"&&min=="0"){
               this.list = [];
           }else{
               this.list = this.oldlist.filter(item=>{
                   return item.rechargeType >= min * 1 && item.rechargeType <= max * 1;
               })
           }
       },
      onConfirm(value){
          this.showtime = false;
           this.year = new Date(value).getFullYear();
          this.month = new Date(value).getMonth() + 1;
          this.list = this.oldlist.filter(item=>{
              let year = new Date(item.time).getFullYear()
              let month = new Date(item.time).getMonth() + 1
              return year==this.year&&month==this.month;
          })  
      },
      formatter(type, val) {
      if (type === 'year') {
        return `${val}`;
      } else if (type === 'month') {
        return `${val}`;
      }
      return val;
    }
  }
}
</script>